export default {
	data() {
		return {
			scenicSpotItems: {
				deploy: {
					row: [
						{
							subassembly: 'strong',
							default: '景区产品',
							cols: 12
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'type',
							connector: {
								props: {
									label: "产品类型"
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'groupid',
							connector: {
								props: {
									label: "产品大类"
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'description',
							connector: {
								props: {
									label: "产品小类"
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'anz',
							connector: {
								props: {
									label: "数量"
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'startdate',
							connector: {
								props: {
									label: "游玩日期"
								}
							}
						},
						{
							cols: 4,
							subassembly: "VTextField",
							property: 'amount',
							connector: {
								props: {
									label: "总价"
								}
							}
						}
					]
				}
			}
		}
	}
}
