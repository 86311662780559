import formItems from './formItems.js';

export default {
    mixins: [formItems],
    // 订单详情tabs
    data() {
        return {
            recordDetails: '/api/order/load', // 订单记录详情
            hisRecordDetails: '/api/order/loadhis', // 历史订单记录详情
            productDetails: '/api/order/load_product', // 订单商品详情
            orderLog: '/api/log/userlog_list' // 订单日志
        }
    },
    methods: {
        async getOrderDetailsTabs(item, hasProduct, hasLog, remark) {
            this.showOperateBtn = false;
            // 订单商品详情
            this.items = [];
            this.show_write_off_card = false
            this.dialog = true;
            let productProps = {};
            let result = {};
            // 订单详情
            let orderItems = JSON.parse(JSON.stringify(this.orderItems));
            let response1 = await this.axios.post(remark === 'history-order' ? this.hisRecordDetails : this.recordDetails, {bookingid: item.bookingid});
            if (response1.code === this.staticVal.Code.Success) {
                // 操作按钮的展示
                this.viewOperateBtn(response1.data, remark);
                if (response1.data.guestInfo?.length > 0) {
                    orderItems.deploy.row.splice(3, 2);
                    this.setFormItems(response1.data.guestInfo, ['guestname', 'mobile', 'idno']
                        , orderItems, 3, ['预订人姓名*', '预订人手机号*', '证件号码*'], 1);
                }
                orderItems.deploy.row.forEach((e) => {
                    if (response1.data[e.property]) {
                        e.default = response1.data[e.property];
                    }
                });
            }
            this.items = this.items.concat(
                [{title: '订单', mainbody: {module: 'forms', binds: orderItems}}]
            );
            // 订单商品详情（包含酒店预订、套餐、票务则加载商品详情）
            if (['R', 'T', 'Z', 'W'].includes(response1.data.ptype)) {
                let response = await this.axios.post(this.productDetails, {
                    bookingid: item.bookingid,
                    ptype: response1.data.ptype,
                    his: remark === 'history-order'
                });
                if (response.code === this.staticVal.Code.Success) {
                    switch (response1.data.ptype) {
                        case "R":
                            // 酒店预订
                            productProps = JSON.parse(JSON.stringify(this.hotelItems));
                            result = response.data.roomtypes;
                            break;
                        case "T":
                            // 票务预订
                            productProps = JSON.parse(JSON.stringify(this.ticketItems));
                            result = response.data.tickets;
                            break;
                        case "Z":
                            // 套餐预订
                            productProps = JSON.parse(JSON.stringify(this.comboItems));
                            // 套餐内容数组
                            result = response.data.productkits;
                            if (result) {
                                this.setFormItems(result.detail, ['description', 'num', 'price']
                                    , productProps, 4, ['套餐内容*', '内容*数量', '内容*价格'], 3);
                            }
                            break;
                        case 'W':
                            productProps = JSON.parse(JSON.stringify(this.scenicSpotItems));
                            result = response.data.spus
                            break;
                        default:
                            break;
                    }
                    if (!this.globalMethods.isEmpty(result)) {
                        // 遍历赋值
                        productProps.deploy.row.forEach((row) => {
                            if (result[row.property]) {
                                row.default = result[row.property];
                            }
                        });
                    }
                }
                this.items.push({title: '商品', mainbody: {module: 'forms', binds: productProps}});
            }
            if (hasLog) {
                this.axios.post(this.orderLog, {type: 'COL', regno: item.bookingid}).then(r => {
                    if (r.code === this.staticVal.Code.Success) {
                        this.tableProps.listData = r.data;
                    }
                });
                this.items = this.items.concat(
                    [{title: '日志', mainbody: {module: 'tables', binds: this.tableProps}}]
                );
            }
        },
        // 操作按钮的展示
        viewOperateBtn(item, remark) {
            if (remark === 'orderRecord') {
                // 预订操作按钮展示
                if (['W', 'P', 'C'].includes(item.mainstatus)) {
                    this.showOperateBtn = true;
                    this.bookingid = item.bookingid;
                    this.appointmentBtnStatus = false;
                    this.confirmBtnStatus = item.mainstatus === 'W';
                } else {
                    this.showOperateBtn = false;
                    // this.bookingid = '';
                }
                this.show_write_off_card = item.cstatus === 'N'
            } else if (remark === 'applyForRefund') {
                // 退款申请操作按钮
                if (item.astatus === 'N') {
                    this.showOperateBtn = true;
                    this.bookingid = item.bookingid;
                } else {
                    this.showOperateBtn = false;
                    this.bookingid = '';
                }
            }
        }
    }
}
