<template>
  <div>
    <common-table
        :itemScope="['mainstatus']"
        :headers="headers"
        :listData="listData"
        @changePage="changePage"
        :loading="loading"
        :btnAction="true"
        ref="ct"
        pageTitle="订单记录"
        :customHtml="true"
        :btnActionEvent="btnActionEvent"
        @otherTableOperate="otherTableOperate"
    >
      <template v-slot:mainstatus="{ item }">
        <!-- 格式化支付状态 -->
        <formatter-field :status="item.mainstatus" field="payStatus"/>
      </template>
      <template v-slot:customHtml>
        <advanced-search
            :fixedConfig="fixedConfig"
            :othersConfig="othersConfig"
            @search="search"
            :extraParams="extraParams"
            @advancedSearch="advancedSearch"
            :hasExportFile="true"
            @exportFile="exportFile"
        />
      </template>
    </common-table>
    <template v-if="dialog">
      <exhibition-data-box
          :contentHeight="500"
          :width="936"
          :dialog.sync="dialog"
          usageMode="tabs"
          :items="items">
        <template v-slot:footer>
          <div style="float: left" class="mt-4">
            <v-btn
                v-if="showOperateBtn"
                :disabled="appointmentBtnStatus || !operatePermissions.includes('82404')"
                color="redness white--text"
                depressed @click="refuseOperate">
              取消订单
            </v-btn>
          </div>
          <div style="float: right" class="mt-4">
            <v-btn
                color="primary"
                class="mr-4"
                depressed @click="showOrHideOrder">
              {{ showOrder ? '隐藏' : '显示' }}订单
            </v-btn>
            <v-btn
                :disabled="appointmentBtnStatus || !operatePermissions.includes('82403')"
                color="primary"
                class="ml-4"
                v-if="showOperateBtn && confirmBtnStatus"
                depressed @click="confirmOperate">
              确认订单
            </v-btn>
            <v-btn
                color="primary"
                class="mr-4"
                depressed
                v-if="show_write_off_card"
                @click="confirmWriteOff">
              确认核销
            </v-btn>
          </div>
          <div style="clear: both;"></div>
        </template>
      </exhibition-data-box>
    </template>
  </div>
</template>

<script>
import commonCurd from "@/mixin/commonCurd";
import api from '@/api/orderManage/orderRecord.js';
import advancedSearch from "@/components/advancedSearch/advancedSearch.vue";
import exhibitionDataBox from '@/components/exhibition-data-box/exhibition-data-box.vue';
import detailsHotel from '../common-order-js/details-hotel.js';
import detailsOrder from '../common-order-js/details-order.js';
import detailsTicket from '../common-order-js/details-ticket.js';
import detailsCombo from '../common-order-js/details-combo.js';
import detailsLog from '../common-order-js/details-log.js';
import detailsScenicSpot from "../common-order-js/details-scenic-spot";
import orderDetailsTabs from '../common-order-js/order-record-details-tabs';
import formatterField from '../formatter.vue';

export default {
  // 订单记录
  name: "order-record",
  components: {advancedSearch, exhibitionDataBox, formatterField},
  mixins: [commonCurd, detailsHotel, detailsOrder, detailsLog, detailsTicket, detailsCombo, orderDetailsTabs, detailsScenicSpot],
  data() {
    return {
      api,
      // 是否展示订单
      showOrder: false,
      extraParams: '',
      tempItem: null,
      dialog: false,
      showOperateBtn: false,
      // 设定一个订单号
      bookingid: '',
      // 操作按钮状态
      appointmentBtnStatus: false,
      confirmBtnStatus: false,
      items: [],
      btnActionEvent: [
        {
          event: 'getDetails',
          icon: 'chakan-fill',
          color: 'primary',
          tooltip: '详情'
        }
      ],
      headers: [
        {text: '订单号', value: 'bookingid'},
        // {text: '订单备注', value: 'memo', width: '120px'},
        {text: '预订人姓名', value: 'bookername'},
        {text: '预订人电话', value: 'tel'},
        {text: '订单金额', value: 'amount'},
        {text: '订单状态', value: 'mainstatus'},
        {text: '支付方式', value: 'payment'},
        {text: '商品类型', value: 'ptypedesc'},
        {text: '订单来源', value: 'agenttypedesc'},
        {text: '创建时间', value: 'createdate'},
        {text: '操作', value: 'actions', width: '60px'}
      ],
      fixedConfig: [
        {type: 'input', label: '输入订单号', property: 'bookingid', width: '180px'},
        {type: 'input', label: '输入预订平台订单号', property: 'outid'}
      ],
      othersConfig: [
        {type: 'input', label: '预订人姓名', property: 'name'},
        {type: 'input', label: '预订人电话', property: 'tel'},
        {type: 'select', label: '订单状态', property: 'mainstatus', multiple: true, arrayType: false},
        {type: 'select', label: '支付方式', property: 'payment'},
        {type: 'select', label: '商品类型', property: 'ptype', items: []},
        {type: 'input', label: '支付订单号', property: 'payno'},
        {type: 'input', label: '支付流水号', property: 'payid'},
        {type: 'dateTime', label: '开始日期', property: 'arrival'},
        {type: 'dateTime', label: '结束日期', property: 'departure'}
      ],
      operatePermissions: [],
      show_write_off_card: false
    }
  },
  methods: {
    advancedSearch() {
      this.$nextTick(() => {
        this.$refs.ct.getTableHeight();
      });
    },
    otherTableOperate(e, item) {
      this.tempItem = item;
      this.bookingid = item.bookingid
      this.showOrder = !item.lhide
      this.getOrderDetailsTabs(item, true, true, 'orderRecord');
    },
    confirmOperate() {
      this.operate(api.confirm, '确认订单');
    },
    refuseOperate() {
      this.confirm.showConfirm('确定取消订单？').then(() => {
        this.operate(this.tempItem?.mainstatus === '待确认' ? api.refuseWaitOrder : api.refuse, '取消订单');
      }).catch(() => {
      });
    },
    operate(api, text) {
      this.axios.post(api, {bookingid: this.bookingid}).then(r => {
        if (r.code === this.staticVal.Code.Success) {
          this.appointmentBtnStatus = true;
          this.list();
          if (this.tempItem) {
            this.getOrderDetailsTabs(this.tempItem, true, true, 'orderRecord');
          }
          this.tempItem = null;
          this.snackbar.success(text + '成功');
        }
      });
    },
    // 订单隐藏显示
    showOrHideOrder() {
      this.confirm.showConfirm('确定要在用户端' + (this.showOrder ? '隐藏' : '显示') + '当前订单吗？').then(() => {
        this.axios.post(api.changeHide, {bookingid: this.bookingid}).then((res) => {
          if (res.code === this.staticVal.Code.Success) {
            this.showOrder = !this.showOrder;
            this.list()
            this.snackbar.success('订单' + (this.showOrder ? '显示' : '隐藏') + '成功');
          }
        })
      }).catch(() => {
      });
    },
    confirmWriteOff() {
      this.confirm.showConfirm('确定核销当前订单吗？').then(() => {
        this.axios.post('/api/order/write_off_card', {bookingid: this.bookingid}).then((res) => {
          if (res.code === this.staticVal.Code.Success) {
            this.list()
            this.snackbar.success('订单核销成功');
            this.show_write_off_card = false
          }
        })
      }).catch(() => {
      });
    }
  },
  mounted() {
    let str = sessionStorage.getItem('operatePermissionKey');
    if (str) {
      this.operatePermissions = JSON.parse(str);
    }
    this.axios.post(this.select_data, {
      keyname: 'mainstatus,payment,ptype', "params": [
        {
          "key": "ptype",
          "value": "COL"
        }
      ]
    }).then((res) => {
      if (res.code === this.staticVal.Code.Success) {
        this.othersConfig[2].items = res.data[0].values;
        this.othersConfig[3].items = res.data[1].values;
        this.othersConfig[4].items = res.data[2].values;
      }
    });
  },
  created() {
    // 外部跳转的额外参数
    this.extraParams = this.$route.query.value;
  }
}
</script>

<style scoped lang="scss">

</style>
